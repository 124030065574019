import Link from "next/link";
import { LargeLogoWithText } from "./Logo";
import { CONTACT_EMAIL, NAV_ROUTES } from "../../constants";
import { getPrivacyPolicy } from "./util";

export default function Nav() {
  return (
    <section className="py-8 px-4 xl:px-10">
      <nav>
        <div className="flex items-center justify-between">
          <Link className="text-xl font-medium" href="/">
            <LargeLogoWithText />
          </Link>
          <div className="lg:hidden">
            <button className="navbar-burger flex items-center rounded p-3 hover:bg-gray-50">
              <svg
                className="block h-4 w-4"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Mobile menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
              </svg>
            </button>
          </div>
          <ul className="hidden lg:ml-auto lg:mr-12 lg:flex lg:w-auto lg:items-center lg:space-x-12">
            {NAV_ROUTES.map(({ label, path }) => (
              <li key={path}>
                <Link className="text-md font-medium" href={path}>
                  {label}
                </Link>
              </li>
            ))}
          </ul>
          <div className="hidden lg:block">
            <a
              className="inline-block rounded bg-red-400 py-3 px-8 text-md font-medium leading-normal text-white transition duration-200 hover:bg-red-300"
              href={`mailto:${CONTACT_EMAIL}`}
            >
              Contact Us
            </a>
          </div>
        </div>
      </nav>
      <div className="navbar-menu fixed top-0 left-0 bottom-0 z-50 hidden w-5/6 max-w-sm">
        <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"></div>
        <nav className="relative flex h-full w-full flex-col overflow-y-auto border-r bg-white py-6 px-6">
          <div className="mb-8 flex items-center">
            <Link
              className="mr-auto text-3xl font-medium leading-none"
              href="/"
            >
              <LargeLogoWithText />
            </Link>
            <button className="navbar-close">
              <svg
                className="h-6 w-6 cursor-pointer text-gray-500 hover:text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <div>
            <ul>
              {NAV_ROUTES.map(({ label, path }) => (
                <li className="mb-1" key={path}>
                  <Link
                    className="block rounded p-4 text-md font-medium text-gray-900 hover:bg-gray-50"
                    href={path}
                  >
                    {label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="mt-auto">
            <div className="pt-6">
              <a
                className="block rounded bg-red-400 py-3 text-center text-md font-medium leading-normal text-white transition duration-200 hover:bg-red-300"
                href={`mailto:${CONTACT_EMAIL}`}
              >
                Contact Us
              </a>
            </div>
            <p className="mt-6 mb-4 text-center text-md text-gray-500">
              <span>© 2022 {getPrivacyPolicy()}.</span>
            </p>
          </div>
        </nav>
      </div>
    </section>
  );
}
