import Head from "next/head";
import { EXAMPLE_PROFILE_URL } from "../../constants";
import Shield from "../../images/shield.svg";
import Letter from "../../images/letter.svg";
import Gem from "../../images/gem.svg";
import Scanner from "../../images/scanner.svg";
import People from "../../images/people.svg";
import Exclamation from "../../images/exclamation.svg";
import Choices from "../../images/choices.jpg";
import NiceRestaurant from "../../images/nice-restaurant.jpg";
import Image from "next/image";

import Slider from "react-slick";
import { AppleAppStore, GooglePlay } from "../common/Buttons";
import Footer from "../common/Footer";
import Nav from "../common/nav";

const screenshots = [
  require("../../images/screenshots/iphone-12-pro--graphite--feed.png"),
  require("../../images/screenshots/iphone-12-pro--graphite--awardwinning.png"),
  require("../../images/screenshots/iphone-12-pro--graphite--map.png"),
  require("../../images/screenshots/iphone-12-pro--graphite--review.png"),
  require("../../images/screenshots/iphone-12-pro--graphite--lists.png"),
  require("../../images/screenshots/iphone-12-pro--graphite--rating.png"),
];

export default function Index() {
  return (
    <>
      <Head>
        <title>Good Food Recommended by Friends | Gwak</title>
        <meta
          name="description"
          content="Gwak is a mobile app that curates restaurant recommendations from friends. Discover interesting places to eat and share your own lists of places to eat with others!"
        />
      </Head>
      <Nav />
      <section className="relative">
        <img
          className="absolute top-0 left-0 mt-32 hidden lg:block"
          src="/page-assets/icons/dots/blue-dot-left-bars.svg"
          alt=""
        />
        <img
          className="absolute top-0 right-0 mt-32 hidden lg:block"
          src="/page-assets/icons/dots/yellow-dot-right.svg"
          alt=""
        />
        <div className="container relative mx-auto px-4 text-center">
          <div className="mx-auto mb-16 max-w-2xl">
            <h1 className="font-heading mt-8 mb-10 text-5xl font-semibold">
              A better way to discover new places to eat.
            </h1>
            <p className="mx-auto mb-10 max-w-xl text-xl text-slate-500">
              Use Gwak to get a curated list of restaurants from people you
              trust — no more aggregated reviews from strangers!
            </p>
            <div className="mb-24 flex items-center justify-center">
              <AppleAppStore />
              <GooglePlay />
            </div>
          </div>
          <div>
            <img
              className="mx-auto lg:max-w-3xl"
              src="/page-assets/images/devices/3-devices-new.png"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="bg-gray-50 pt-20">
        <div className="container relative mx-auto px-4">
          <div className="-mx-4 flex flex-wrap items-center">
            <div className="w-full px-4 lg:w-1/2">
              <div className="max-w-lg">
                <h2 className="mb-10 text-4xl font-semibold lg:mb-10 lg:pr-8">
                  Tired of scrolling through the same Yelp search results?
                </h2>
                <p className="mb-6 text-xl text-gray-500">
                  These days, it feels like there is both
                  <strong> too much information </strong> and
                  <strong> too little variation</strong> when it comes to
                  discovering new restaurants.
                </p>
                <div className="grid grid-cols-2">
                  <div>
                    <p className="text-xl text-gray-500 underline">Too many:</p>
                    <ul className="mb-6 list-inside text-xl text-gray-500">
                      <li>⭐ 4.3 Star ratings</li>
                      <li>🤖 Bots</li>
                      <li>💸 Ads</li>
                      <li>🥸 Fake reviews</li>
                    </ul>
                  </div>
                  <div>
                    <p className="text-xl text-gray-500 underline">
                      Not enough:
                    </p>
                    <ul className="mb-6 list-inside text-xl text-gray-500">
                      <li>💎 Real hidden gems</li>
                      <li>📢 Sharing between friends</li>
                      <li>🤗 Human connection</li>
                      <li>
                        ✨ Variability (always sorting on rating or number of
                        reviews)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative w-full px-4 lg:w-1/2">
              <img
                className="rounded-xl object-cover"
                src={Choices.src}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="pt-20">
        <div className="container mx-auto px-4">
          <div className="relative -mx-4 flex flex-wrap-reverse items-center">
            <div className="w-full px-4 lg:w-2/5">
              <img
                className="lg:h-128 h-96 w-full rounded-xl object-cover"
                src={NiceRestaurant.src}
                alt=""
              />
            </div>
            <div className="relative w-full px-4 lg:w-3/5">
              <div className="relative lg:pl-10">
                <h2 className="font-heading mt-8 mb-10 text-4xl font-semibold lg:mb-10 lg:pr-8">
                  The Solution
                </h2>
                <p className="mb-6 text-xl text-gray-500">
                  Gwak is a mobile app that lets you follow specific people
                  whose taste you trust. You only see reviews of restaurants for
                  the people you follow. <b>Nothing else.</b>
                </p>
                <p className="mb-6 text-xl text-gray-500">
                  Going to Chicago for the weekend, but don&apos;t know where to
                  eat? Follow some local Chicago friends and see what they
                  suggest.
                </p>
                <p className="mb-6 text-xl text-gray-500">
                  Don&apos;t know anyone in Chicago? Discover some local experts
                  and give their curation a try!
                </p>
                <p className="mb-6 text-xl text-gray-500">
                  Whether you are a trailblazer finding the next hidden gem or
                  the person who wants to spend less time sorting through the
                  noise of Yelp and Google Maps, there&apos;s something for
                  everyone.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-20">
        <div className="container mx-auto px-4">
          <div className="-mx-4 flex flex-wrap">
            <div className="mb-12 w-full px-4 lg:mb-0 lg:w-1/2">
              <h2 className="font-heading mb-10 text-4xl font-semibold">
                How it works
              </h2>
              <p className="mb-12 max-w-lg text-xl text-gray-500">
                Gwak is an app that lets you follow specific tastemakers so you
                can personalize your own feed.{" "}
                <strong>
                  You only see recommendations from people you follow.
                </strong>
              </p>
              <span className="text-sm font-semibold text-red-400">
                Download now!
              </span>
              <div className="flex">
                <AppleAppStore />
                <GooglePlay />
              </div>
            </div>
            <div className="w-full px-4 lg:w-1/2">
              <div className="bg-gray-75 rounded-xl px-10">
                <div className="border-b py-8">
                  <div className="flex items-start items-center">
                    <span className="mr-6 flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-400 text-white">
                      1
                    </span>
                    <p className="text-gray-500">
                      Follow friends and influencers whose taste you trust.
                    </p>
                  </div>
                </div>
                <div className="border-b py-8">
                  <div className="flex items-start items-center">
                    <span className="mr-6 flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-400 text-white">
                      2
                    </span>
                    <p className="text-gray-500">
                      Discover, eat, and rate restaurants yourself.
                    </p>
                  </div>
                </div>
                <div className="py-8">
                  <div className="flex items-start items-center">
                    <span className="mr-6 flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-400 text-white">
                      3
                    </span>
                    <p className="text-gray-500">
                      Create your own lists and share them with others!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container mx-auto px-4">
          <h2 className="font-heading mb-10 text-center text-4xl font-semibold">
            Features
          </h2>
          <div className="-mx-3 flex flex-wrap">
            <div className="mb-6 w-full px-3 md:w-1/2 lg:w-1/3">
              <div className="bg-gray-75 h-full rounded-lg p-6 md:p-8">
                <span className="mb-8 flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-full bg-blue-400 md:mb-12">
                  <img src={Gem.src} alt="gem" />
                </span>
                <div>
                  <h3 className="font-heading mb-4 text-2xl font-semibold">
                    Find the gems
                  </h3>
                  <p className="text-lg text-gray-500">
                    Cut through the noise and hype. Lean on friends and get the
                    inside scoop.
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-6 w-full px-3 md:w-1/2 lg:w-1/3">
              <div className="bg-gray-75 h-full rounded-lg p-6 md:p-8">
                <span className="mb-8 flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-full bg-red-400 md:mb-12">
                  <img src={Exclamation.src} alt="exclamation" />
                </span>
                <div>
                  <h3 className="font-heading mb-4 text-2xl font-semibold">
                    Ignore the masses
                  </h3>
                  <p className="text-lg text-gray-500">
                    No more 4.3 vs 4.5 star analysis. Show me my friend&apos;s
                    once-a-month regular spot.
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-6 w-full px-3 md:w-1/2 lg:w-1/3">
              <div className="bg-gray-75 h-full rounded-lg p-6 md:p-8">
                <span className="mb-8 flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-full bg-teal-400 md:mb-12">
                  <img src={Shield.src} alt="shield with check" />
                </span>
                <div>
                  <h3 className="font-heading mb-4 text-2xl font-semibold">
                    Trustworthy sources
                  </h3>
                  <p className="text-lg text-gray-500">
                    No fake reviews, only people you trust.
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-6 w-full px-3 md:w-1/2 lg:mb-0 lg:w-1/3">
              <div className="bg-gray-75 h-full rounded-lg p-6 md:p-8">
                <span className="mb-8 flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-full bg-orange-400 md:mb-12">
                  <img src={Letter.src} alt="letter" />
                </span>
                <div>
                  <h3 className="font-heading mb-4 text-2xl font-semibold">
                    Create your own curation
                  </h3>
                  <p className="text-lg text-gray-500">
                    Calling all foodies! Gwak makes it easy to start sharing the
                    love. Highlight what stood out.
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-6 w-full px-3 md:w-1/2 lg:mb-0 lg:w-1/3">
              <div className="bg-gray-75 h-full rounded-lg p-6 md:p-8">
                <span className="mb-8 flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-full bg-yellow-400 md:mb-12">
                  <img src={Scanner.src} alt="scanner" />
                </span>
                <div>
                  <h3 className="font-heading mb-4 text-2xl font-semibold">
                    Share once
                  </h3>
                  <p className="text-lg text-gray-500">
                    Review once, share your lists with anyone.{" "}
                    <a className="underline" href={EXAMPLE_PROFILE_URL}>
                      Check out an example profile
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-6 w-full px-3 md:w-1/2 lg:mb-0 lg:w-1/3">
              <div className="bg-gray-75 h-full rounded-lg p-6 md:p-8">
                <span className="mb-8 flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-full bg-gray-900 md:mb-12">
                  <img src={People.src} alt="people" />
                </span>
                <div>
                  <h3 className="font-heading mb-4 text-2xl font-semibold">
                    Bring back the human connection
                  </h3>
                  <p className="text-lg text-gray-500">
                    Food tastes better when you can say &quot;Hey, I loved that
                    place you recommended&quot;!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-20">
        <div className="container mx-auto px-4 ">
          <h2 className="font-heading mb-10 text-4xl font-semibold">Video</h2>
          <div className="flex justify-center">
            <div className=" w-full lg:w-1/2">
              <video
                preload="none"
                id="vid1"
                controls
                className="video-js"
                poster="/video-poster.png"
                data-setup="{}"
              >
                <source src="/demo-video.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container mx-auto px-4 ">
          <h2 className="font-heading mb-10 text-4xl font-semibold">
            Screenshots
          </h2>
          <Slider
            dots
            swipeToSlide
            arrows={false}
            autoplay
            slidesToShow={5}
            slidesToScroll={1}
            autoplaySpeed={2000}
            responsive={[
              {
                breakpoint: 640,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                },
              },
            ]}
          >
            {screenshots.map((screenshot, index) => (
              <div key={index} className="flex justify-center">
                <Image className="px-2" src={screenshot} alt="screenshot" />
              </div>
            ))}
          </Slider>
        </div>
      </section>

      <section className="py-20">
        <div className="container mx-auto px-4">
          <h2 className="font-heading mb-10 text-center text-4xl font-semibold">
            FAQs
          </h2>
          <div className="-mx-4 flex flex-wrap">
            <div className="mb-12 w-full px-4 md:w-1/2 lg:mb-20 lg:w-1/3">
              <div className="flex">
                <div>
                  <h3 className="font-heading mb-1 text-2xl font-semibold">
                    Does it cost anything?
                  </h3>
                  <p className="text-base text-gray-500">
                    Nope! Gwak is free to use.
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-12 w-full px-4 md:w-1/2 lg:mb-20 lg:w-1/3">
              <div className="flex">
                <div>
                  <h3 className="font-heading mb-1 text-2xl font-semibold">
                    If I create a list of recommendations, do people need to
                    sign up for Gwak to see my list?
                  </h3>
                  <p className="text-base text-gray-500">
                    No! Gwak auto-generates a lightweight profile web page for
                    you that you can share with anyone. Even users who
                    don&apos;t have the app yet. Check out this{" "}
                    <a className="underline" href={EXAMPLE_PROFILE_URL}>
                      example profile page
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-50 py-20">
        <div className="container mx-auto px-4">
          <h2 className="font-heading mb-10 text-center text-4xl font-semibold">
            Download Gwak now!
          </h2>
          <div className="flex flex-wrap justify-center space-x-4">
            <GooglePlay />
            <AppleAppStore />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
