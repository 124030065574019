import { Prisma } from '@prisma/client';
import Link from 'next/link';
import DoubleThumbsUp from '../../images/ratings/double-thumbs-filled.png';
import Eye from '../../images/ratings/eye.png';
import ThumbDown from '../../images/ratings/thumb-down.png';
import ThumbsUp from '../../images/ratings/thumbs-up.png';

const VIBE: {
  [key: string]: string;
} = {
  hiddenGem: 'Hidden Gem',
  lunchSpot: 'Lunch Spot',
  quickBite: 'Quick Bite',
  weeknightDinner: 'Weeknight Dinner',
  dateNight: 'Date Night',
  bucketList: 'Bucket List',
};

const PRICE: {
  [key: string]: string;
} = {
  cheap: 'Cheap',
  reasonablyPriced: 'Reasonably Priced',
  bitPricey: 'Bit Pricey',
  expensive: 'Expensive',
};

export const getVibeBadges = (vibe: object) =>
  Object.entries(vibe)
    .filter(([key, val]) => val)
    .map(([key, val]) => key).map((key) => (
    <div
      className="bg-orange-200 text-orange-500 mb-1 mr-1 rounded py-1 px-2 align-middle font-medium"
      key={key}
    >
      {VIBE[key]}
    </div>
  ));

export const getPriceBadges = (price: object) =>
  Object.entries(price)
    .filter(([key, val]) => val)
    .map(([key, val]) => key).map((key) => (
    <div
      className="mb-1 mr-1 rounded bg-green-200 py-1 px-2 align-middle font-medium text-green-500"
      key={key}
    >
      {PRICE[key]}
    </div>
  ));

export const getPrivacyPolicy = () => (
  <Link href="/privacy">Privacy Policy</Link>
);

export const RatingToThumbsIcon: {
  [key: number]: JSX.Element;
} = {
  3: <img className="h-6" src={DoubleThumbsUp.src} alt="Two thumbs up"/>,
  2: <img className="h-4" src={ThumbsUp.src} alt="Thumbs up"/>,
  1: <img className="h-4" src={ThumbDown.src} alt="Thumbs down"/>,
  0: <img className="h-5" src={Eye.src} alt="Watchlist"/>,
};
