import Link from "next/link";
import { Discord, Facebook, Instagram, Reddit, Twitter } from "./Buttons";
import { APP_NAME, CONTACT_EMAIL, NAV_ROUTES } from "../../constants";
import LogoWithText from "../../images/logo-with-text.svg";
import { getPrivacyPolicy } from "./util";

export default function Footer() {
  return (
    <section className="py-10">
      <div className="container mx-auto px-4">
        <div className="border-b border-gray-100">
          <div className="mb-6 flex flex-wrap items-start items-center justify-between">
            <div className="mb-5 w-full lg:mb-0 lg:w-1/5">
              <Link
                className="inline-block text-xl font-semibold text-gray-900"
                href="/"
              >
                <img
                  className="h-10"
                  src={LogoWithText.src}
                  alt=""
                  width="auto"
                />
              </Link>
            </div>
            <div className="w-full lg:w-auto">
              <ul className="flex flex-wrap items-center space-x-4 text-md lg:space-x-8">
                {NAV_ROUTES.map(({ label, path }) => (
                  <li className="mb-2 md:mb-0" key={label}>
                    <Link className="text-md font-medium" href={path}>
                      {label}
                    </Link>
                  </li>
                ))}
                <li className="mb-2 md:mb-0">
                  <a
                    className="text-md font-medium"
                    href={`mailto:${CONTACT_EMAIL}`}
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-wrap items-center justify-between">
            <p className="mt-8 text-md text-gray-500">
              {getPrivacyPolicy()} © {APP_NAME} 2022
            </p>
            <div className="order-first mt-8 flex sm:order-last">
              <Twitter />
              <Reddit />
              <Discord />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
