export const GooglePlay = () => {
  return (
    <a href="https://play.google.com/store/apps/details?id=com.getgwak.gwak">
      <img src="/page-assets/buttons/google-play.svg" alt="Google Play Link" />
    </a>
  );
};

export const AppleAppStore = () => {
  return (
    <a className="mr-4" href="https://apps.apple.com/us/app/gwak/id1626025395">
      <img
        src="/page-assets/buttons/app-store.svg"
        alt="Apple App Store link"
      />
    </a>
  );
};

export const Twitter = () => {
  return (
    <a
      className="mr-4 flex h-10 w-10 items-center justify-center rounded-full bg-gray-50"
      href="https://twitter.com/gwakhq"
    >
      <img src="/page-assets/buttons/twitter.svg" alt="Twitter link" />
    </a>
  );
};

export const Instagram = () => {
  return (
    <a
      className="mr-4 flex h-10 w-10 items-center justify-center rounded-full bg-gray-50"
      href="https://www.instagram.com/gwakhq"
    >
      <img src="/page-assets/buttons/instagram.svg" alt="Instagram link" />
    </a>
  );
};

export const Facebook = () => {
  return (
    <a
      className="mr-4 flex h-10 w-10 items-center justify-center rounded-full bg-gray-50"
      href="https://fb.me/gwakhq"
    >
      <img src="/page-assets/buttons/facebook.svg" alt="Facebook link" />
    </a>
  );
};

export const Reddit = () => {
  return (
    <a
      className="mr-4 flex h-10 w-10 items-center justify-center rounded-full bg-gray-50 p-3"
      href="https://reddit.com/r/gwakapp"
    >
      <img src="/page-assets/buttons/reddit.svg" alt="Reddit link" />
    </a>
  );
};

export const Discord = () => {
  return (
    <a
      className="mr-4 flex h-10 w-10 items-center justify-center rounded-full bg-gray-50 p-3"
      href="https://discord.gg/PYEpy9VDGy"
    >
      <img src="/page-assets/buttons/discord.svg" alt="Reddit link" />
    </a>
  );
};
