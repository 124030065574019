export const APP_NAME = 'Gwak';
export const CONTACT_EMAIL = 'contact@getgwak.com';
export const BASE_URL = 'https://getgwak.com';
export const ICON_MAP: {
  [key: string]: string;
} = {
  Favorites: '/page-assets/images/star-favorites.svg',
  All: '/page-assets/images/lightning.svg',
};
export const EXAMPLE_PROFILE_URL =
  '/profiles/352c42a1-de04-41be-bb83-fc0ca81ecfad';

export const NAV_ROUTES = [
  {label: 'Who is this for?', path: '/who-is-this-for'},
  {label: 'Create your own lists', path: '/curators'},
  {label: 'Explore profiles', path: '/explore'},
  // { label: "Consumers", path: "/consumers" },
  {label: 'About', path: '/about'},
];
